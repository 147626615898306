import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/helper'
import store from './store'
import { Plugins } from '@capacitor/core'
import vuetify from './plugins/vuetify'
import './plugins/axios'

const { SplashScreen } = Plugins

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify,
  mounted () {
    SplashScreen.show()

    Notification.requestPermission().then((permission) => {
      if (permission !== 'granted') {
        this.$toastNotif({
          message: 'To ensure you receive important updates about your flight schedule, please consider allowing notifications',
          type: 'info'
        })
      }
    })
  }
}).$mount('#app')
